import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { useHelper } from '@react-three/drei';
//import { useControls } from 'leva';

const Lights = ({...props}) => {
    // console.log("lights Module");
    // console.log("LightType: ", props.lightType);
   
    const allLights = {
        ambientLight: useMemo(() => new THREE.AmbientLight(
            props.color,
            props.intensity,
        ),[props]),
        directionalLight: useMemo(() => new THREE.DirectionalLight(
            props.color,
            props.intensity,
        ),[props]),
        spotLight : useMemo(() => new THREE.SpotLight(
            props.color,
            props.intensity,
            props.distance,
            props.angle,
            props.penumbra,
            props.decay
        ),[props])
    }

    /* to avoid shadow stripe artifacts */
    allLights["spotLight"].shadow.bias = -0.0005;
    allLights["spotLight"].shadow.mapSize.width = 1024*4;
    allLights["spotLight"].shadow.mapSize.height = 1024*4;
    allLights["spotLight"].castShadow = true;
    //let lightColor = Object.values(slColor).toString();

    // const lightProps = useControls({
    //     color: 'aqua',
    //     intensity: 1,
    //     position: [3, 3, 3],
    //     distance: 10,
    //     angle: Math.PI / 4,
    //     penumbra: 0.5,
    //     decay: 0.5,
    //   })
    const debugLightRef = useRef()
    useHelper(debugLightRef, THREE.SpotLightHelper, 'black');

    return (
        <mesh {...props}>
            <primitive object={allLights[props.lightType]}
                position={props.Lightpos}

              />
            {/* <spotLight ref={debugLightRef} castShadow {...lightProps} /> */}

            {/* {console.log("pos: ",props.Lightpos, "target>", props.LightTarget)} */}

            { (props.lightType === "spotLight") && 
                <primitive object={allLights[props.lightType].target} position={props.LightTarget} />
            }
          
        </mesh>
  )
        }

export default Lights;

