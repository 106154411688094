import React, { useState, useEffect } from 'react';
import { useStore } from '../store';
import { ProgressBarstyles } from './ProgressBar.styles'

export const ProgressBar = ({ progress }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [hover, setHover] = useState(false);

  const setProgressFinished = useStore(state => state.setProgressFinished);

  const Hoverstyles = {
    startButton: {
      padding: '10px 20px',
      marginTop: '50%',
      backgroundColor: '#282828',
      color: '#fff',
      border: '1px solid lightblue',
      borderRadius: '8px',
      fontSize: '1.5rem',
      fontWeight: '100',
      fontFamily: 'Arial, Helvetica, sans-serif',
      cursor: 'pointer',
      transition: 'all 0.2s ease-in-out',
      ...(hover && {
        backgroundColor: '#404040',
        boxShadow: '0px 0px 10px #000',
      }),
    }
  };

  useEffect(() => {
    if (progress === 100) {
      setIsVisible(false);
    }
  }, [progress]);

  return (
    <>
      <div style={ProgressBarstyles.welcomeMessage}>
        {/* <Loader /> */}
        <div>
          <span style={ProgressBarstyles.titleContainer}>Welcome to J - World</span>
        </div><br />
        <div>

          {isVisible && (
            <div style={ProgressBarstyles.progressContainer}>
              <div style={{ ...ProgressBarstyles.progressBar, width: `${progress}%` }} />
              {`${progress}`}
            </div>
          )}
          {!isVisible && (
            <button
            style={Hoverstyles.startButton}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setProgressFinished(true)}>Start</button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProgressBar;