import React, { useState, useRef } from 'react'
import { useSpring } from '@react-spring/three'
import { MainCenter } from './scenes/MainCenter4';
import { LoungeRoom } from './scenes/LoungeRoom3';
import { useStore } from './store';

const SceneSwitcher = () => {
    const sceneARef = useRef(null);
    const sceneBRef = useRef(null);
    const currentScene = useStore(state => state.currentScene);
    const startTransition = useStore(state => state.startTransition )

    const [open, setOpen] = useState(false)
    
    const props = useSpring({ open: Number(open) })
      

    const SceneA = () => {
        return (
            <mesh
                position={[8, 2, -12]}
                receiveShadow
                castShadow
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                    startTransition("sceneB")
                    // changeScene("sceneB");
                    }
                }
                ref={sceneARef}
            >
                <sphereGeometry args={[0.8, 64, 64]} />
                <meshStandardMaterial color="lightgrey" roughness={0} />
            </mesh>
        )
    }
    const SceneB = () => {
        return (
            <mesh
                position={[-3, 4, 0]}
                receiveShadow
                castShadow
                ref={sceneBRef}
            >
                <sphereGeometry args={[0.8, 64, 64]} />
                <meshStandardMaterial color="#2872aa" roughness={0} />
            </mesh>
        )
    }

    function EmptyObject(){
        console.log("empty");
    }

    function SwitchComponents({ active, children }) {
       // Switch all children and return the "active" one
        // test
        return children.filter(child => child.props.name === active)
    }

    return (
        <>
            <SwitchComponents active={currentScene}>
                <EmptyObject name="empty" />
                <SceneA name="initial" 
                open={open}
                style={{ opacity: props.open.to([0, 1], [1, 0]), transform: props.open.to((o) => `translate3d(-50%,${o * 50 - 100}px,0)`) }}
                />
                <MainCenter position={[0, 1, -4]}
                    name="initial" />

                <SceneA name="sceneA" />
                <MainCenter position={[0, 1, -4]}
                    name="sceneA" />

               <SceneB name="sceneB" />
                <LoungeRoom 
                    name="sceneB"
                    // position={[0,1,0]}// if scale is disabled
                    // position={[0, 1, -10]}
                    scale={[0.2,0.2,0.2]}
                />

            </SwitchComponents>
            {/* below to also add fade transition */}
            {/* {(currentScene === "initial") && <SceneA />}
            {(currentScene === "sceneA") && <SceneA />}
            {(currentScene === "sceneB") && <SceneB />} */}
        </>
    )
}

export default SceneSwitcher
