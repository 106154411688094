import React, { useState , Suspense, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
// import { useThree } from '@react-three/fiber';
import { Environment, Stats } from '@react-three/drei';
import * as THREE from 'three';
// import {Loader} from './Loader/Loader'
import {ProgressBar} from './Loader/ProgressBar'

//components
import './Home.styles.css';
// import Ground from './Ground';
import Shape from './Shape';

import SceneSwitcher from './SceneSwitcher';

import CameraContainer from './Camera';
import { useStore } from './store';
// import { Leva } from 'leva';
// import { ResizeObserver } from '@juggle/resize-observer';
// global.ResizeObserver = ResizeObserver;

const Home = () => {
    const [camAngle, setCamAngle] = useState("default");
    //const isVertical = useStore(state => state.isVertical);
    const setVertical = useStore(state => state.setVertical);
    const isProgressFinished = useStore(state => state.isProgressFinished);

    const Views = () => {
        //runs on resize
        //const viewport = useThree((state) => state.viewport)
        //viewport.width++;
        // console.log(viewport.width, viewport.height);
        const width = window.innerWidth;
        const height = window.innerHeight;
        //console.log(width, height);
        const config = { vertical: false }
        if(width / height > 1) {
            config.vertical = false
            setVertical(false)
        }
        else {
            config.vertical = true
            setVertical(true);
        }

        //console.log("Viewport vertical? ", config.vertical)
        //console.log("Viewport vertical? ", isVertical);

        //group.current.scale.x = viewport().width/ 5;
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 5000);
    //     }, []);

    // if (isLoading) {
    //     return <Loader />;
    // }

    // const intervalRef = useRef(null);

    // useEffect(() => {
    //     intervalRef.current = setInterval(() => {
    //         if (progress < 100) {
    //         setProgress(progress + 10);
    //         console.log("progress:", progress);
    //         }
    //         else{
    //         setIsVisible(false);
    //         }
    //   }, 200)

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let interval = setInterval(() => {
        if (progress < 100) {
            setProgress(progress + 10);
        } else {
            clearInterval(interval);
        }
        }, 200);

        return () => clearInterval(interval);
    }, [progress]);

    return (
        <>
            {/* Welcome  */}

            {/* This component can also be named as MainView */}

            {/* <div id='canvasContainer'> */}
            
            {/* <Loader /> */}
                    {!isProgressFinished ? (
                    // <div className="welcomeMessage">
                    //     {/* <Loader /> */}
                    //     <div>
                    //         <span className='title-container'>Welcome to J - World</span>
                    //     </div><br />
                    //     <div>
                            
                            <ProgressBar progress={progress} />
                            // {/* <button 
                            // className='startBtn'
                            // onClick={() => setStartScene(false)}
                            // >Start</button> */}
                            
                    //     </div>
                    // </div>
                    ):
                (<Canvas 
                    onCreated={({ gl }) => {
                        gl.shadowMap.enabled = true;
                        gl.shadowMap.type = THREE.PCFSoftShadowMap;
                    
                    }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0
                      }}
                    >
                    <Views />
                    {/* <Leva */}
                    {/* //hidden={true} */}
                    {/* /> */}

                    <Stats showPanel={0} className="stats" /> 
                    <Suspense fallback={null}>
                        {/* <Frames /> */}
                        {/* <Button /> */}
                        {/* <Ground /> */}
                        

                        <Shape />
                        <SceneSwitcher changeCameraToScene={camAngle => setCamAngle(camAngle)}/>

                        <CameraContainer camAngle={camAngle} />

                        <Environment background>
                            <mesh>
                                <sphereGeometry args={[50, 100, 100]} />
                                <meshBasicMaterial
                                    color="#6f8089" 
                                    // color="#000000" 
                                    // color="#1e83bd" 
                                    intensity={0.1}
                                    side={THREE.BackSide} />
                            </mesh>
                        </Environment>
                    </Suspense>
                </Canvas>
                )}
            {/* </div> */}
        </>
    )
}

export default Home;
