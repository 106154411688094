import React,{ useState, useRef } from 'react';
import * as THREE from 'three';
import { useSpring, a } from '@react-spring/three';

import { useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei';


const Shape = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [hovered, setHovered] = useState(false);
    const allShapes = {
        box: new THREE.BoxGeometry(1, 1, 1),
        cylinder: new THREE.CylinderGeometry(1, 1, 1, 32),
        donut: new THREE.TorusGeometry(0.5, 0.2, 3, 20)
    }
    const props = useSpring({
        scale: isClicked ? [1.5,1.5,1.5] : [1, 1, 1] ,
        hover: hovered ? "hotpink" : "lightgrey"
    });

    const cubeRef = useRef()
    const htmlRef = useRef()

    
    useFrame((state, delta) => {
      if (cubeRef.current && htmlRef.current) {
        // Get the position and rotation of the cube
        const position = cubeRef.current.position
        const rotation = cubeRef.current.rotation

        // Update the position and rotation of the HTML element
        htmlRef.current.style.transform = `translate3d(${position.x}px, ${position.y}px, ${position.z}px) rotateX(${rotation.x}rad) rotateY(${rotation.y}rad) rotateZ(${rotation.z}rad)`
      }
    })

    return (
        <>
        <a.mesh
            {...props}
            position={[5, 2, 0]}
            onPointerOver={() => setHovered(true)}
            onPointerOut={() => setHovered(false)}
            onClick={() => setIsClicked(!isClicked)}
            scale={props.scale}
            castShadow>
            {/* <boxBufferGeometry attach="geometry" /> */}
            <primitive object={allShapes["box"]} attach="geometry" />
            <meshLambertMaterial attach="material" color={hovered ? "hotpink" : "lightgrey"} />
        </a.mesh>
        <a.mesh{...props}
            position={[5, 4, 0]}
            onPointerOver={() => setHovered(true)}
            onPointerOut={() => setHovered(false)}
            onClick={() => setIsClicked(!isClicked)}
            scale={props.scale}
            castShadow>
            <primitive object={allShapes["box"]} attach="geometry" />
            <meshLambertMaterial attach="material" color={hovered ? "hotpink" : "lightgrey"} />
            </a.mesh>
            <Html center
                position={[5, 9, -8]}
            >
                <div
                    ref={htmlRef}
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'wheat',
                        'text- decoration': 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                    }}>
                .
            </div>
        </Html>
        </>
    ) 
}

export default Shape
