

export const ProgressBarstyles = {
    titleContainer: {
        position: 'absolute',
        top: '25%',
        left: '50%',
        transform: 'translate(-50%, 0)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '5vw',
        fontFamily: '\'Times New Roman\', Times, serif',
      },
      welcomeMessage: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'black',
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'lightgrey',
    },
    progressContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '25%',
      height: '20px',
      backgroundColor: '#cccccc',
      borderRadius: '10px',
    },
    progressBar: {
      height: '100%',
      backgroundColor: '#4CAF50',
      borderRadius: '10px',
    },
    
  };
  