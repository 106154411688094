import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import anglesToRadians from '../utils/angle';
import { useStore } from './store';
import GUI from 'lil-gui';
import { useControls, folder, button } from 'leva';
import { useThree } from '@react-three/fiber';

const CameraContainer = () => {
    const mainCameraRef = useRef(null);
    const orbitControlsRef = useRef(null); 
    let ocCam = orbitControlsRef.current;
    let duration = 2;
    const changeScene = useStore(state => state.changeScene);
    const nextScene = useStore(state => state.nextScene);
    const isVertical = useStore(state => state.isVertical);

    const debugActive = useStore(state => state.debugActive);
    //const gui = useStore(state => state.gui);
    let tl = gsap.timeline()

    const Resize = () => {
        const { size } = useThree()
        if(!!mainCameraRef.current){
            let asp = mainCameraRef.current;
            asp.aspect = size.width / size.height;
            //console.log(mainCameraRef.current.aspect,size);
        }
    }

    const CamModel = {
        currentSceneNames: ["sceneA", "sceneB"],
        OrbControlCam: {
            unlocked: function () {
                if(!!ocCam){
                    //console.log("unlocked orb",ocCam);
                    ocCam.maxDistance = 30;
                    ocCam.minDistance = 0;
                    ocCam.minAzimuthAngle = 0;
                    ocCam.maxAzimuthAngle = Math.PI * 1.999;
                    ocCam.minPolarAngle = 0;
                    ocCam.maxPolarAngle = Math.PI;
                    // mainCameraRef.cam = true;
                }
            },
            debug(){
                ocCam.enabled = true;
                ocCam.screenSpacePanning = true;
                ocCam.enableKeys = false;
                ocCam.zoomSpeed = 0.25;
                ocCam.enableDamping = true;


            },
            default: function () {
                // console.log("default orb");
                ocCam.maxDistance = 15;
                ocCam.minDistance = 0;
                ocCam.minAzimuthAngle = 0;
                ocCam.maxAzimuthAngle = anglesToRadians(45);
                ocCam.minPolarAngle = anglesToRadians(40);
                ocCam.maxPolarAngle = anglesToRadians(80);
                // mainCameraRef.cam = true;
                
            },
            sceneA: function () {
                if(!!ocCam){
                    ocCam.maxDistance = 15;
                    ocCam.minDistance = 0;
                    ocCam.minAzimuthAngle = -Infinity;
                    ocCam.maxAzimuthAngle = Infinity;
                    ocCam.minPolarAngle=anglesToRadians(40)
                    ocCam.maxPolarAngle=anglesToRadians(80)

                    // ocCam.enableDamping=true
                    ocCam.enablePan=false
                    ocCam.rotateSpeed=1.2
                    ocCam.enableZoom=false
                }
            },
            sceneB: function () {
                //if(!!ocCam){
                    ocCam.maxDistance = scB_maxDistance;
                    ocCam.minDistance = scB_minDistance;
                    ocCam.minAzimuthAngle = anglesToRadians(scB_minAzimuth);
                    ocCam.maxAzimuthAngle = anglesToRadians(scB_maxAzimuth);
                    ocCam.minPolarAngle = anglesToRadians(scB_minPolar);
                    ocCam.maxPolarAngle = anglesToRadians(scB_maxPolar);
                    // mainCameraRef.cam = true;
                    ocCam.rotateSpeed *= -1;
                //}
            },
            sceneC: function () {
                    ocCam.maxDistance = scC_maxDistance;
                    ocCam.minDistance = scC_minDistance;
                    ocCam.minAzimuthAngle = anglesToRadians(scC_minAzimuth);
                    ocCam.maxAzimuthAngle = anglesToRadians(scC_maxAzimuth);
                    ocCam.minPolarAngle = anglesToRadians(scC_minPolar);
                    ocCam.maxPolarAngle = anglesToRadians(scC_maxPolar);
                    // mainCameraRef.cam = true;
                    //ocCam.rotateSpeed = 1;
                    //ocCam.update();
            }

        },
        transitions: {
            default: () => {
                //async
                // console.log("default transition");
                ocCam.enableRotate = false;
                ocCam.enableZoom = false;

                tl.to(mainCameraRef.current.position, {
                    duration: 2, ease: "power1.inOut",
                    x: 0,
                    y: 5,
                    z: 8,
                    onComplete: () => {
                        // console.log("Animation defaultpos completed")
                    }
                },">");
            },
            zoomTosceneA: () => {
                ocCam.enableRotate = false;
                ocCam.enableZoom = false;

                tl.to(mainCameraRef.current.position, {
                    duration: 2, ease: "power1.inOut",
                    x: 0,
                    y: 5,
                    z: 12,
                    onComplete: () => {
                        //console.log("Animation 4 completed")
                        //loads model before zooming out
                    }

                },">");


                tl.to(ocCam.target, {
                    duration: 2, ease: "power1.inOut",
                    x: 0,
                    y: 4,
                    z: -8,
                    onUpdate: function () {
                        ocCam.update();
                    },
                    onComplete: () => {
                        //console.log("Animation target sceneA completed")
                    }

                },"<");

                CamModel.OrbControlCam.sceneA();
                ocCam.enableRotate = true;
                ocCam.enableZoom = false;
            },
            zoomTosceneB: () => {
                //console.log("zoomTosceneB transition", mainCameraRef.current.position);
                ocCam.enableRotate = false;
                ocCam.enableZoom = false;

                tl.to(mainCameraRef.current.position, {
                    duration: duration, ease: "power1.inOut",
                    x: scB_mainCamPos[0],
                    y: scB_mainCamPos[1],
                    z: scB_mainCamPos[2],
                    onComplete: () => {
                        //console.log("Animation 2 completed")
                        //loads model before zooming out
                        changeScene("sceneB");
                    }

                },">+=1.1");

                tl.to(ocCam.target, {
                    duration: duration, ease: "power1.inOut",
                    x: scB_orbTarget[0],
                    y: scB_orbTarget[1],
                    z: scB_orbTarget[2],
                    onUpdate: function () {
                        ocCam.update();
                    },
                    onComplete: () => {
                        //console.log("Animation 3 completed")
                        CamModel.OrbControlCam.sceneB();
                    }

                },"<");

                //await CamModel.sleep(4500);
                ocCam.enableRotate = true;
                ocCam.enableZoom = true;
            },
            zoomTosceneC: () => {
                //console.log("zoomTosceneC transition", mainCameraRef.current.position);
                ocCam.enableRotate = false;
                ocCam.enableZoom = false;

                tl.to(mainCameraRef.current.position, {
                    duration: duration, ease: "power1.inOut",
                    x: scC_mainCamPos[0],
                    y: scC_mainCamPos[1],
                    z: scC_mainCamPos[2],
                    onComplete: () => {
                        //console.log("Animation sceneC-campos completed")
                        //loads model before zooming out
                        //changeScene("sceneC");
                    }

                },">+=1.2");

                tl.to(ocCam.target, {
                    duration: duration, ease: "power1.inOut",
                    x: scC_orbTarget[0],
                    y: scC_orbTarget[1],
                    z: scC_orbTarget[2],
                    onUpdate: function () {
                        ocCam.update();
                    },
                    onComplete: () => {
                        //console.log("Animation sceneC-target completed")
                        CamModel.OrbControlCam.sceneC();
                    }

                },"<");

                //await CamModel.sleep(4500);
                ocCam.enableRotate = true;
                ocCam.enableZoom = true;

            },
            zoomOutTosceneB: function () {
                ocCam.enableRotate = false;
                ocCam.enableZoom = false;

                tl.to(mainCameraRef.current.position, {
                    duration: duration, ease: "power1.inOut",
                    x: scB_mainCamPos[0],
                    y: scB_mainCamPos[1],
                    z: scB_mainCamPos[2],
                },">");

                tl.to(ocCam.target, {
                    duration: duration, ease: "power1.inOut",
                    x: scB_orbTarget[0],
                    y: scB_orbTarget[1],
                    z: scB_orbTarget[2],
                },"<");

                // await this.sleep(1500);
                ocCam.enableRotate = true;
                ocCam.enableZoom = true;
                }
        },
        sleep(ms) 
        {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

    }


    //gsap animations go in useEffect/useLayoutEffect
    
    useEffect(() => {
        switch(nextScene){
            case "sceneA":
                //console.log("switchcase: transition sceneA");
                // load model before transition
                changeScene("sceneA");
                CamModel.transitions.default();
                CamModel.OrbControlCam.unlocked();
                CamModel.transitions.zoomTosceneA();

                break;
            case "sceneB":
                //console.log("switchcase: transition sceneB");
                // condition if prev. scene was scene A. run default
                //CamModel.transitions.default();

                CamModel.OrbControlCam.unlocked();
                CamModel.transitions.zoomTosceneB();
                break;
            case "sceneC":
                //console.log("switchcase: transition sceneC");
                CamModel.OrbControlCam.unlocked();
                CamModel.transitions.zoomTosceneC();
                break;
            default:
                // console.log("Camera.js received no scene");
                break;
        }
    },[nextScene, CamModel.OrbControlCam, CamModel.transitions, changeScene])
    
    useEffect(() => {
      if(orbitControlsRef.target != null){
        //console.log("ocCam checkpoint",ocCam.target, ocCam.target.y, ftarget);
        console.log("target", orbtarget);
        // set({ ftarget: {
        //     x: ocCam.target.x,
        //     y: ocCam.target.y,
        //     z: ocCam.target.z}});
        // }
        set({ orbtarget: {
            x: ocCam.target.x,
            y: ocCam.target.y,
            z: ocCam.target.z}
        });
        }
        // eslint-disable-next-line
    },[orbitControlsRef])

    //console.log("camera gui");
    const { camPosition, camfov, camNearFar } = useControls("PerspectiveCamera", {
        'position': folder({
            camPosition:{
                value: [0,5,8],
                min: -20,
                max: 20,
                step: 1,
            },
        }),
        camfov: { value: ( isVertical ? 85 : 60 ) },
        camNearFar: {
            label: 'Near/Far',
            value: [1,30],
            min: 0.1,
            max: 100
        }
    })
    /*remove objects once done debugging*/
    const [{ orbtarget, 
        scB_mainCamPos, scB_orbTarget,
        scB_minDistance, scB_maxDistance,
        scB_minAzimuth, scB_maxAzimuth, scB_minPolar, scB_maxPolar,
        scC_mainCamPos, scC_orbTarget,
        scC_minDistance, scC_maxDistance,
        scC_minAzimuth, scC_maxAzimuth, scC_minPolar, scC_maxPolar}
        , set] = useControls("OrbitControls",
        () => ({
        "initial": folder({
            orbtarget:{
                // value: {
                //     x: 0,
                //     y: 4,
                //     z: -8
                // },
                value: [0,4,-8],
                // x: { min: -10, max: 10, step: 0.1},
                // y: { min: -2, max: 10, step: 0.1},
                // z: { min: -20, max: 8, step: 0.1},
                // min: -20,
                // max: 20,
                // step: 1,
            },
            reset: button(() => {
                set({
                    orbtarget: [0, 4, -8],
                });
            }),
        }),
        "sceneB": folder({
            scB_mainCamPos:{ label: "CamPos:", value: [0,2,-9] },
            scB_orbTarget:{ label: "target:", value: [0,2,-10] },
            scB_minDistance:{ label: "min_Dist", value: 0 },
            scB_maxDistance:{ label: "max_Dist:", value: 15 },
            scB_minAzimuth:{ label: "min_Azimuth:", value: -45 , min:-360, max: 360, step: 1 },
            scB_maxAzimuth:{ label: "max_Azimuth:", value: 45, min:-360, max: 360 , step: 1 },
            scB_minPolar:{ label: "min_Polar:", value: 70 , min:-360, max: 360, step: 1 },
            scB_maxPolar:{ label: "max_Polar:", value: 70, min:-360, max: 360, step: 1 },
            // scB_azimuth: {
            //     label: "Azimuth",
            //     value: [-5,5],
            //     min: -Math.PI, 
            //     max: Math.PI 
            // },
            // scB_polar: {
            //     label: "polar",
            //     value: [70,70],
            //     min: -Math.PI, 
            //     max: Math.PI 
            // },
            scB_reset: button(() => {
                set({
                    scB_mainCamPos: [0,2,-9],
                    scB_orbTarget: [0,2,-10],
                    scB_minDistance: 0,
                    scB_maxDistance: 15,
                    scB_minAzimuth: -45,
                    scB_maxAzimuth: 45,
                    scB_minPolar: 70,
                    scB_maxPolar: 70,
                })
            })
        }),
        "sceneC": folder({
            scC_mainCamPos:{ label: "CamPos:", value: [1.2,1.8,-13] },
            scC_orbTarget:{ label: "target:", value: [2,1.8,-13] },
            scC_minDistance:{ label: "min_Dist", value: 0 },
            scC_maxDistance:{ label: "max_Dist:", value: 30 },
            /* azimuth: here its semicircle behind the camera. values in degrees*/
            scC_minAzimuth:{ label: "min_Azimuth:", value: -90 , min:-360, max: 360, step: 1 },
            scC_maxAzimuth:{ label: "max_Azimuth:", value: -90, min:-360, max: 360 , step: 1 },
            scC_minPolar:{ label: "min_Polar:", value: 90 , min:-360, max: 360, step: 1 },
            scC_maxPolar:{ label: "max_Polar:", value: 90, min:-360, max: 360, step: 1 },
            // scC_azimuth: {
            //     label: "Azimuth",
            //     value: [2,2],
            //     min: -Math.PI, 
            //     max: Math.PI 
            // },
            // scC_polar: {
            //     label: "polar",
            //     value: [70,70],
            //     min: -Math.PI, 
            //     max: Math.PI 
            // },
            scC_reset: button(() => {
                set({
                    scC_mainCamPos: [1.2,8,-13],
                    scC_orbTarget: [2,1.8,-13],
                    scC_minDistance: 0,
                    scC_maxDistance: 15,
                    scC_minAzimuth: -90,
                    scC_maxAzimuth: 90,
                    scC_minPolar: 90,
                    scC_maxPolar: 90,
                    //scC_azimuth: [2,2],
                    //scC_polar: [70,70],
                })
            })
        }),


    }))

    useEffect(() => {
        /*remains constant*/
        if(debugActive){
            const gui = new GUI({width: 300}).close()
            //gui.show(true);
            //console.log(ocCam, mainCameraRef);
            let debugFolder, positionDebugFolder, targetDebugFolder;
            debugFolder = gui.addFolder("Camera")
            positionDebugFolder = debugFolder.addFolder("CameraPosition");
            let objA = mainCameraRef.current;
            let objB = ocCam;
            positionDebugFolder.add(objA.position,'x').min(-20).max(20).step(0.1).name('pos x').listen()
            positionDebugFolder.add(objA.position,'y').min(-20).max(20).step(0.1).name('pos y').listen()
            positionDebugFolder.add(objA.position,'z').min(-20).max(20).step(0.1).name('pos z').listen()
            positionDebugFolder.add(objA,'fov').min(0).max(100).step(0.1).listen()
            .onChange(function(){
                  objA.updateProjectionMatrix();
            }).name('camera.fov');
            

            if(!!objB){
                targetDebugFolder = debugFolder.addFolder("OrbTarget");
                 targetDebugFolder.add(objB.target,'x').min(-10).max(10).step(0.1).name('x').listen()
                 targetDebugFolder.add(objB.target,'y').min(-2).max(10).step(0.1).name('y').listen()
                 targetDebugFolder.add(objB.target,'z').min(-20).max(8).step(0.1).name('z').listen()
                
                //targetDebugFolder.add(objB).reset().name('reset');
                targetDebugFolder.add(objB,'minDistance').listen()
                targetDebugFolder.add(objB,'maxDistance').listen()
                targetDebugFolder.add(objB,'minAzimuthAngle')
                targetDebugFolder.add(objB,'maxAzimuthAngle')
                targetDebugFolder.add(objB,'minPolarAngle').listen()
                targetDebugFolder.add(objB,'maxPolarAngle').listen()
                targetDebugFolder.add(objB,'update');
                // targetDebugFolder.add(objB.minPolarAngle,'minPolarAngle').min(-3).max(0).step(1).name('minPolarAngle');
                // targetDebugFolder.add(objB.maxPolarAngle,'maxPolarAngle').min(0).max(30).step(1).name('maxPolarAngle');
                debugFolder.add(objB, 'enablePan');
                let cam = false;
                let cameraToggle = {unlockCamera:false}
                debugFolder.add(cameraToggle, 'unlockCamera')
                    .onChange(() => {
                        cam ? CamModel.OrbControlCam.default(): CamModel.OrbControlCam.unlocked();
                    })
            }


            return () => {
                gui.destroy()
            }
        }
    },[debugActive,ocCam, CamModel.OrbControlCam])

    return (
        <>
            {/* initial Setup */}
            <PerspectiveCamera
                makeDefault
                //fov={60}
                // near={1}
                // far={30}
                //position={[0, 5, 8]}
                ref={mainCameraRef}
                //debug args
                fov={ camfov }
                near={ camNearFar[0] }
                far={ camNearFar[1] }
                position={ camPosition }
            />
            {/* < CameraHelper /> */}
            <OrbitControls
                makeDefault
                ref={orbitControlsRef}
                minPolarAngle={anglesToRadians(40)}
                maxPolarAngle={anglesToRadians(80)}
                enableDamping={true}
                enablePan={false}
                rotateSpeed={1.2}
                zoomSpeed={0.8}
                //target={[0, 4, -8]}
                enableZoom={false}
                target={ orbtarget }
                // enableRotate={false}
                //onChange={() => {setDebugTarget()}}

            />
            {/* <ChangeCameraToNextScene /> */}

            <Resize />
            {/* <TweakCamera /> */}
            

       </>
    )
}

export default CameraContainer;

