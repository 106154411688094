/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useGLTF, useAnimations, useCursor, Html } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useStore } from '../store';
import Lights from '../Light';
import '../Home.styles.css';
// import { useDrag } from '@use-gesture/react';

export function MainCenter({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF("/models/mainCenter4.gltf");
  const { actions } = useAnimations(animations, group);
  // const [hovered, setHovered] = useState(false);
  const startTransition = useStore(state => state.startTransition);
  const hovered = useStore(state => state.hovered);
  const setHovered = useStore(state => state.setHovered);
  // const [showText, setShowText] = useState(true);


  const MCmodel = {
    scenePos: [0, 0.7, -12],
    Lights: {
      ambientLight1: {
        lightType: "ambientLight",
        color: "white",
        intensity: 0.1
      },
      directionalLight1: {
        lightType: "directionalLight",
        lightPos: [0, 2, -2],
        target: [0, -40, -4],
        color: "white",
        intensity: 0.4

      },
      spotLight1: {
        lightType: "spotLight",
        lightPos: [-4, 22, 6],
        // lightPos: [-4, 15, 2],
        target: [0, 1, 0],
        color: "#f9f9f9",
        intensity: 0.8,
        angle: 0.92,
        penumbra: 0.5,
      },
      spotLight2: {
        lightType: "spotLight",
        lightPos: [0, 4, -30],
        // lightPos: [4, 15, 2],
        target: [0, 1, 0],
        //color: "green",
        color: "#d9d7d7",
        intensity: 0.4,
        angle: 0.5,
        penumbra: 0.5
      }
    },
    AnimFns: {
      animationsList: Object.keys(actions),
      resetAnimations() {
        /* resets all animations*/
        const keys = Object.keys(actions);
        keys.forEach((key) => {
          //reset all
          if (actions[key] == null) { return }
          actions[key].fadeOut().stop();
          actions[key].reset();
        });
      },
      animPlay(key) {
        actions[key]
          .setEffectiveTimeScale(1)
          .setEffectiveWeight(1)
          .play();
        actions[key].repetitions = 1;
        actions[key].loop = 1;
        actions[key].setDuration(0.38);
        actions[key].clampWhenFinished = true;
      },
      onDoorsHovered() {
        const va = MCmodel.AnimFns;
        va.resetAnimations()
        va.animationsList.forEach((key) => {
          if ((key === "DoorLeftOpen") || (key === "DoorRightOpen")) {
            va.animPlay(key)
          }
        })
      },
      onDoorsHoveredExit: useCallback(() => {
        //onDoorsHoveredExit(){
        const va = MCmodel.AnimFns;
        va.resetAnimations()
        va.animationsList.forEach((key) => {
          if ((key === "DoorLeftClosed") || (key === "DoorRightClosed")) {
            va.animPlay(key)
          }
        })

        setHovered(false);
      // eslint-disable-next-line
      }, [hovered]),
      // },



    },
    CamFns: {
      onHandleDoorClicked() {
        // props.changeCameraToDoor(true);
        // changeScene("sceneB");
        startTransition("sceneB");

        // Mainroom
      }
    }
  }

  let MCm = MCmodel;



  const MainCenterLights = () => {
    let AmLight_1 = MCm.Lights.ambientLight1;
    let SpLight_1 = MCm.Lights.spotLight1;

    return (
      <mesh>

        {/* front light on the left */}
        <Lights {...AmLight_1} Lightpos={AmLight_1.lightPos} LightTarget={AmLight_1.target} />
        <Lights {...SpLight_1} Lightpos={SpLight_1.lightPos} LightTarget={SpLight_1.target} />
        {/* <Lights {...LRm.spotLight2} Lightpos={LRm.spotLight2.lightPos} LightTarget={LRm.spotLight2.target}/> */}

        {/* <Lights {...LRm.directionalLight1} Lightpos={LRm.directionalLight1.lightPos} LightTarget={LRm.directionalLight1.target}/> */}
        {/* back light on the left */}

        {/* back light on the right */}
        {/* <spotLightHelper /> */}
      </mesh>
    )
  }

  // Animations

  useEffect(() => {
    // if(state.mouse ==)
    // actions.doorLanim.startAt(5);

    // console.log("Hovered var:",hovered);
    if (hovered) {
      MCm.AnimFns.onDoorsHovered();
    } else {
      MCm.AnimFns.onDoorsHoveredExit();
    }
    return () => {
      //clean up
      MCm.AnimFns.resetAnimations()
    }
  }, [hovered, MCm.AnimFns])


  useCursor(hovered);
  
  // const bind = useDrag(({ offset: [x, y], isHovering }) => {
  //   console.log("hover bind fn");
  //   if (isHovering) {
  //     // MCm.AnimFns.onDoorsHovered();
  //     console.log("hover true");
  //   }
  //   else {
  //     console.log("hover false");
  //     // MCm.AnimFns.onDoorsHoveredExit();
  //   }
  // }, { drag: false, hover: true });


  // const [scaleObject, setScaleObject] = useState([0, 0, 0]);
  const groupRef = useRef();
  const [isScaling, setIsScaling] = useState(true);

  useEffect(() => {
    let animationFrameId;
    let start = null;
    function animate(timestamp) {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      if (progress < 2000) {
        groupRef.current.scale.x += (1 - groupRef.current.scale.x) * 0.1;
        groupRef.current.scale.y += (1 - groupRef.current.scale.y) * 0.1;
        groupRef.current.scale.z += (1 - groupRef.current.scale.z) * 0.1;
        animationFrameId = requestAnimationFrame(animate);
      } else {
        setIsScaling(false);
      }
    }
    if (isScaling) {
      animationFrameId = requestAnimationFrame(animate);
    }
    return () => cancelAnimationFrame(animationFrameId);
  }, [isScaling]);

  useFrame((state, delta) => {
    if (!isScaling) {
      groupRef.current.scale.set(1, 1, 1);
    }
  });

  //for doors
  // const HoverContext = React.createContext(false);

  return (
    
    <group ref={group} {...props}
      dispose={null}
    >
      <MainCenterLights />
      <Html
      center // Adds a -50%/-50% css transform (default: false) [ignored in transform mode]
      fullscreen // Aligns to the upper-left corner, fills the screen (default:false) [ignored in transform mode]
      // distanceFactor={10} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
      zIndexRange={[0, 0]} // Z-order range (default=[16777271, 0])
      // occlude
      // onOcclude={group}
      >
        <div className='mainCenterInfoDiv'>
          Testing.
        </div>

      </Html>

      <group name="Scene" 
          ref={groupRef} scale={[0, 0, 0]}>
        <mesh
          name="FwallPillarR"
          castShadow
          receiveShadow
          geometry={nodes.FwallPillarR.geometry}
          material={materials.mat_FrontDecor}
          position={[4.52, 3.13, -1.87]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          name="FwallPillarL"
          castShadow
          receiveShadow
          geometry={nodes.FwallPillarL.geometry}
          material={materials.mat_FrontDecor}
          position={[-4.55, 3.13, -1.87]}
          rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
        />
        <group name="BuildingMain" position={[0, 0, -5.5]} >
          <mesh
            name="BuildingMain_mesh"
            castShadow
            receiveShadow
            geometry={nodes.BuildingMain_mesh.geometry}
            material={materials.mat_window}
          />
          <mesh
            name="BuildingMain_mesh_1"
            castShadow
            receiveShadow
            geometry={nodes.BuildingMain_mesh_1.geometry}
            material={materials.mat_walls}
          />
          <mesh
            name="BuildingMain_mesh_2"
            castShadow
            receiveShadow
            geometry={nodes.BuildingMain_mesh_2.geometry}
            material={materials.mat_roof}
          />
          <mesh
            name="BuildingMain_mesh_3"
            castShadow
            receiveShadow
            geometry={nodes.BuildingMain_mesh_3.geometry}
            material={materials.mat_frame}
          />
          <mesh
            name="BuildingMain_mesh_4"
            castShadow
            receiveShadow
            geometry={nodes.BuildingMain_mesh_4.geometry}
            material={materials.mat_window_bars}
          />
        </group>
        
        {/* <HoverContext.Provider value={[hovered, setHovered]}> */}
          <group name="DoorRight"
            // {...bind()}
            position={[0.64, 0.84, -4.01]}
            rotation={[0, Math.PI / 4, 0]}
            onClick={() => MCm.CamFns.onHandleDoorClicked()}
            onPointerOver={() => setHovered(true)}
            onPointerOut={() => setHovered(false)}
          >
            <mesh
              name="DoorRight_mesh"
              castShadow
              receiveShadow
              geometry={nodes.DoorRight_mesh.geometry}
              material={materials.mat_frame}
              onPointerOver={() => setHovered(true)}
              onPointerOut={() => setHovered(false)}
            />
            <mesh
              name="DoorRight_mesh_1"
              castShadow
              receiveShadow
              geometry={nodes.DoorRight_mesh_1.geometry}
              material={materials.mat_door_panels}
              onPointerOver={() => setHovered(true)}
              onPointerOut={() => setHovered(false)}
            />
          </group>
          <group name="DoorLeft"
            position={[-0.64, 0.84, -4.02]}
            rotation={[0, -Math.PI / 4, 0]}
            onClick={() => MCm.CamFns.onHandleDoorClicked()}
            onPointerOver={() => setHovered(true)}
            onPointerOut={() => setHovered(false)}
            // scale={[1.5,1.5,1.5]}
          >
            <mesh
              name="DoorLeft_mesh"
              castShadow
              receiveShadow
              geometry={nodes.DoorLeft_mesh.geometry}
              material={materials.mat_frame}
              onPointerOver={() => setHovered(true)}
              onPointerOut={() => setHovered(false)}
            />
            <mesh
              name="DoorLeft_mesh_1"
              castShadow
              receiveShadow
              geometry={nodes.DoorLeft_mesh_1.geometry}
              material={materials.mat_door_panels}
              onPointerOver={() => setHovered(true)}
              onPointerOut={() => setHovered(false)}
            />
          </group>
        {/* </HoverContext.Provider> */}
        <mesh
          name="InnerRoom"
          castShadow
          receiveShadow
          geometry={nodes.InnerRoom.geometry}
          material={materials.mat_InnerRoom}
          position={[0.03, 1.25, -5.36]}
          rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          name="FrontWallLedge"
          castShadow
          receiveShadow
          geometry={nodes.FrontWallLedge.geometry}
          material={materials.mat_FwallLedge}
          position={[-0.02, 2.92, -3.51]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
          scale={[0.6, 1, 1.54]}
        />
        <mesh name="GreenPanel"
          castShadow
          receiveShadow
          geometry={nodes.GreenPanel.geometry}
          material={materials.mat_GreenPanel}
          position={[-0.03, 4.48, -3.98]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
          scale={[2.78, 1, 1.89]}
        />
        <mesh
          name="TerraceRoom"
          castShadow
          receiveShadow
          geometry={nodes.TerraceRoom.geometry}
          material={materials.mat_TerraceRoom}
          position={[0.07, 6.37, -5.66]}
        />
        <mesh
          name="RightWallWhiteBox"
          castShadow
          receiveShadow
          geometry={nodes.RightWallWhiteBox.geometry}
          material={materials.mat_RightWallBox}
          position={[5.04, 1.56, -4.43]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1, 1, 0.45]}
        />
        <mesh
          name="BwallMidDividerUpper"
          castShadow
          receiveShadow
          geometry={nodes.BwallMidDividerUpper.geometry}
          material={materials.mat_BwallDivider}
          position={[0.04, 4.1, -7]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={[-1, -0.39, -1.02]}
        />
        <group
          name="BwallGarageDoor"
          position={[3.52, 1.65, -7.06]}
          rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
          scale={[0.2, 0.22, 1.65]}
        >
          <mesh
            name="BwallGarageDoor_mesh"
            castShadow
            receiveShadow
            geometry={nodes.BwallGarageDoor_mesh.geometry}
            material={materials.mat_greyGarageDoor}
          />
          <mesh
            name="BwallGarageDoor_mesh_1"
            castShadow
            receiveShadow
            geometry={nodes.BwallGarageDoor_mesh_1.geometry}
            material={materials.mat_greyGarageDoorFrame}
          />
        </group>
        <group
          name="LwallGreybox"
          position={[-5.04, 1.38, -6.06]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[1, 0.29, 1]}
        >
          <mesh
            name="LwallGreyBox_mesh"
            castShadow
            receiveShadow
            geometry={nodes.LwallGreyBox_mesh.geometry}
            material={materials.mat_LwallGreyBox}
          />
          <mesh
            name="LwallGreyBox_mesh_1"
            castShadow
            receiveShadow
            geometry={nodes.LwallGreyBox_mesh_1.geometry}
            material={materials["mat_LwallGB-Cover"]}
          />
          <mesh
            name="LwallGreyBox_mesh_2"
            castShadow
            receiveShadow
            geometry={nodes.LwallGreyBox_mesh_2.geometry}
            material={materials["mat_LwallGB-Handle"]}
          />
        </group>
        <mesh
          name="TerraceTankRight"
          castShadow
          receiveShadow
          geometry={nodes.TerraceTankRight.geometry}
          material={materials.mat_TerraceTank}
          position={[-2.78, 6.21, -3.57]}
          scale={[1, 1.19, 1]}
        />
        <mesh
          name="TerraceTankLeft"
          castShadow
          receiveShadow
          geometry={nodes.TerraceTankLeft.geometry}
          material={materials.mat_TerraceTank}
          position={[-4.02, 6.21, -3.58]}
          scale={[1, 1.19, 1]}
        />
        <mesh
          name="LPipe"
          castShadow
          receiveShadow
          geometry={nodes.LPipe.geometry}
          material={materials.Pipe}
          position={[-5, 3.02, -6.84]}
          scale={[0.65, 1.03, 0.65]}
        />
        <mesh
          name="BuildingWindowmidframes"
          castShadow
          receiveShadow
          geometry={nodes.BuildingWindowmidframes.geometry}
          material={materials.mat_frame}
          position={[0, 0, -5.5]}
        />
        <mesh
          name="TerraceRoomDoor"
          castShadow
          receiveShadow
          geometry={nodes.TerraceRoomDoor.geometry}
          material={materials.mat_terraceRoomDoor}
          position={[-1.09, 6.37, -5.95]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          scale={[-1, -0.31, -1]}
        />
        <mesh
          name="TerraceFloor"
          castShadow
          receiveShadow
          geometry={nodes.TerraceFloor.geometry}
          material={materials.mat_roofFloor}
          position={[0, 0, -5.5]}
        />
        <mesh
          name="TerraceRoomFloor"
          castShadow
          receiveShadow
          geometry={nodes.TerraceRoomFloor.geometry}
          material={materials.mat_roofFloor}
          position={[0.07, 6.37, -5.66]}
        />
        <mesh
          name="BwallMidDividerLower"
          castShadow
          receiveShadow
          geometry={nodes.BwallMidDividerLower.geometry}
          material={materials.mat_BwallDivider}
          position={[0.04, 2.17, -7]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={[-1, -0.39, -1.02]}
        />
        <group
          name="BackDoor"
          position={[-3.78, 0.92, -7.02]}
          scale={[1.42, 1.15, 1]}
        >
          <mesh
            name="Backdoor_mesh"
            castShadow
            receiveShadow
            geometry={nodes.Backdoor_mesh.geometry}
            material={materials.mat_frame}
          />
          <mesh
            name="Backdoor_mesh_1"
            castShadow
            receiveShadow
            geometry={nodes.Backdoor_mesh_1.geometry}
            material={materials["mat_doorPanels-Grey"]}
          />
        </group>
        <mesh
          name="Footpath"
          castShadow
          receiveShadow
          geometry={nodes.Footpath.geometry}
          material={materials.mat_footpath}
          position={[-3.5, 0, 0.4]}
          scale={[1.2, 1, 1.2]}
        />
        <mesh
          name="Road"
          castShadow
          receiveShadow
          geometry={nodes.Road.geometry}
          material={materials.mat_Road}
          position={[0, -0.6, -4.43]}
          scale={[50, 0.18, 50]}
        />
        <mesh
          name="Footpath_edge"
          castShadow
          receiveShadow
          geometry={nodes.Footpath_edge.geometry}
          material={materials.mat_footpathEdge}
          position={[-3.5, 0.01, 0.4]}
          scale={[1.2, 1, 1.2]}
        />
        <mesh
          name="DoorShade"
          castShadow
          receiveShadow
          geometry={nodes.DoorShade.geometry}
          material={materials.mat_doorShade}
          position={[-0.01, 1.96, -3.62]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          name="TextSignMainCenter"
          castShadow
          receiveShadow
          geometry={nodes.TextSignMainCenter.geometry}
          material={materials.mat_MainCenterSign}
          position={[-0.1, 4.82, -3.72]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.92}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/mainCenter4.gltf");
