/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from 'react';
import { useCursor, useGLTF, Html } from '@react-three/drei';
import { useStore } from '../store';
import Lights from '../Light'
import { useControls, folder, button } from 'leva';

export function LoungeRoom({ ...props }) {
    const LoungeRoomRef = useRef()
    const { nodes, materials } = useGLTF('/models/LoungeRoom.gltf')
    // const LRmodel = useStore(state => state.LRmodel);
    const [hovered, setHovered] = useState(false);
    const startTransition = useStore(state => state.startTransition);

    //const gui = useStore(state => state.gui);
    
    const LRmodel= {
        scenePos: [0,0.7,-12],

        Lights: {
            ambientLight1: {
                lightType: "ambientLight",
                color: "white",
                intensity: 0.4
            },
            directionalLight1: {
                lightType: "directionalLight",
                lightPos: [0, 2, -2],
                target: [0, -40, -4],
                color: "white",
                intensity: 0.4

            },
            spotLight1: {
                lightType: "spotLight",
                lightPos: [0, 30, -4],
                // lightPos: [-4, 15, 2],
                target: [0, 1, -4],
                color: "#d7d7d7",
                intensity: 0.4,
                angle: 0.72,
                penumbra: 0.1,
            },
            spotLight2: {
                lightType: "spotLight",
                lightPos: [0, 20, -4],
                // lightPos: [4, 15, 2],
                target: [-6, 1, -4],
                //color: "green",
                color: "#d7d7d7",
                intensity: 0.6,
                angle: 0.72,
                penumbra: 0.1

            }
        },
        CamFns: {
            onHandleDoorClicked() {
                // props.changeCameraToDoor(true);
                startTransition("sceneA");
                // Mainroom
            },
            onHandleMainBoardClicked() {
                // props.changeCameraToDoor(true);
                //alert("Board clicked");
                startTransition("sceneC");
                // Mainroom
            },
            onHandleWallRightClicked(){
                //alert("wall clicked");
                //this fn runs when board is clicked
                // TO DO: enable click only if currentScene sceneC
                startTransition("sceneB");
            }
        }
    }

    let LRm = LRmodel;


    const LoungeRoomLights = () => {

        // let AmLight_1 = LRm.Lights.ambientLight1;
        let SpLight_1 = LRm.Lights.spotLight1;

        return(
            <mesh>
            {/* <ambientLight intensity={0.4} color="#ffffff"/> */}
                
                {/* front light on the left */}
                {/* <Lights {...AmLight_1} Lightpos={AmLight_1.lightPos} LightTarget={AmLight_1.target}/> */}

                <Lights {...SpLight_1} Lightpos={SpLight_1.lightPos} LightTarget={SpLight_1.target}/>
                {/* <Lights {...LRm.spotLight2} Lightpos={LRm.spotLight2.lightPos} LightTarget={LRm.spotLight2.target}/> */}

                {/* <Lights {...LRm.directionalLight1} Lightpos={LRm.directionalLight1.lightPos} LightTarget={LRm.directionalLight1.target}/> */}
                {/* <SpotLightObj {...LRm.sl2}/> */}
                {/* back light on the left */}
                   
                {/* back light on the right */}
                {/* <spotLightHelper /> */}
            </mesh>
        )
    }

    const [{ roomPos, roomRotate }, set] = useControls("Room",() => ( {
        'position': folder({
            roomPos:{
                value: [0,0.7,-12],
                min: -20,
                max: 20,
                step: 0.1,
                // onChange: (v) => {
                //     LoungeRoomRef.position = v
                // }
            },
            roomRotate: {
                value: [0,0,0],
                min: -20,
                max: 20,
                step: 0.1,
            },
            reset: button(() => {
                set({
                    roomPos: [0, 0.7, -12],
                    roomRotate: [0, 0, 0]
                });
            }),

        })
    }));
  
    // changes cursor from arrow 
    useCursor(hovered)
    // const [isModalVisible, setIsModalVisible] = useState(false);
  
    //for info board
    // const cubeRef = useRef()
    // const htmlRef = useRef()

    
    // useFrame((state, delta) => {
    //   if (cubeRef.current && htmlRef.current) {
    //     // Get the position and rotation of the cube
    //     const position = cubeRef.current.position
    //     const rotation = cubeRef.current.rotation

    //     // Update the position and rotation of the HTML element
    //     htmlRef.current.style.transform = `translate3d(${position.x}px, ${position.y}px, ${position.z}px) rotateX(${rotation.x}rad) rotateY(${rotation.y}rad) rotateZ(${rotation.z}rad)`
    //   }
    // })
    /*-------Info board--------------- */


    return (
      <group ref={LoungeRoomRef} {...props} dispose={null}
      //position={LRm.scenePos}
      position={ roomPos }
      rotation={ roomRotate }
  
  >
      {/* {debugActive ? <DebugLR />: null}} */}
  
      <LoungeRoomLights />
      <Html position={[0, 0, -15]} center>
        {/* modal testing */}
        {/* {!isModalVisible && (
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Open Modal
          </Button>
        )}
        <Modal bodyStyle={{ width: 500, height: 250 }} visible={isModalVisible} onOk={() => setIsModalVisible(false)} onCancel={() => setIsModalVisible(false)}>
          <div>TEST</div>
        </Modal> */}
      </Html>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WallCenter.geometry}
        material={materials.mat_Wall}
        position={[0, 4.52, -13.75]}
        // onClick={() => {setIsModalVisible(true)}}
        
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WallLeft.geometry}
        material={materials.mat_Wall}
        position={[-12.78, 4.52, -4.51]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WallRight.geometry}
        material={materials.mat_Wall}
        position={[12.76, 4.52, -4.53]}
        rotation={[0, Math.PI / 2, 0]}
        onClick={() => {LRm.CamFns.onHandleWallRightClicked()}}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.MainBoard.geometry}
        material={materials.mat_mainBoard}
        position={[12.5, 5.59, -4]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        onClick={() => {LRm.CamFns.onHandleMainBoardClicked()}}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      />
      <group position={[-12.85, 3, -4.82]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.DoorRight_1.geometry}
          material={materials.mat_door1}
        />
        {/* yellow portion of door */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.DoorRight_2.geometry}
          material={materials["mat_door1.001"]}
          onClick={() => {LRm.CamFns.onHandleDoorClicked()}}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.MainBoardInner.geometry}
        material={materials["mat_mainBoard.001"]}
        position={[12.39, 5.59, -4]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      />
      <group position={[8.29, 0.13, -3.61]} rotation={[Math.PI / 2, 0, 1.49]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.recepDesk.geometry}
          material={materials.mat_topandBandrecepDesk}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.recepDesk_1.geometry}
          material={materials.mat_frontofrecepDesk}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.recepDesk_2.geometry}
          material={materials.mat_innerrecepDesk}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.recepDesk_3.geometry}
          material={materials.mat_partitionsrecepDesk}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.recepDesk_4.geometry}
          material={materials.mat_topDeskLegs_recepDesk}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sideTable.geometry}
        material={materials.mat_sideTable}
        position={[1.17, 0.16, -10.05]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CoffeeTable.geometry}
        material={materials.Material}
        position={[-6, 1.12, -6]}
        rotation={[-Math.PI, 0, 0]}
      />
      <group position={[-12.85, 3, -1.77]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.DoorLeft_1.geometry}
          material={materials.mat_door1}
        />
        {/* yellow portion of door */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.DoorLeft_2.geometry}
          material={materials["mat_door1.001"]}
          onClick={() => {LRm.CamFns.onHandleDoorClicked()}}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ElevatorDoor1.geometry}
        material={materials.mat_ElevatorDoor}
        position={[5, 3.61, -14.24]}
        scale={[1.2, 3.6, 0.25]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ElevatorDoor2.geometry}
        material={materials.mat_ElevatorDoor}
        position={[7.39, 3.61, -14.51]}
        scale={[1.2, 3.6, 0.25]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ElevatorDoorFrame.geometry}
        material={materials.mat_ElevatorDoorFrame}
        position={[6.2, 7.47, -13.88]}
        scale={[0.23, 0.23, 0.39]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sofabase.geometry}
        material={materials.mat_sofaCustomBase}
        position={[-11.74, 1.35, -10.95]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.11, 1.16, 1.16]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sofaCube_1001.geometry}
        material={materials.mat_sofaCustomBase}
        position={[-9.35, 6.42, -8.51]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Floor.geometry}
        material={materials.mat_floor}
        position={[0, 0, -3.59]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.singleSofa.geometry}
        material={materials.mat_smallSofa}
        position={[-7.7, 0.16, 3.33]}
        rotation={[Math.PI / 2, 0, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Base.geometry}
        material={materials.White}
        position={[11.84, 1.53, 2.64]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Feet.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Top.geometry}
          material={materials.White}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.DrawerBottom.geometry}
        material={materials.White}
        position={[11.84, 1.53, 2.64]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.DrawerKnobBottom.geometry}
          material={materials.Black}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.DrawerMid.geometry}
        material={materials.White}
        position={[11.84, 1.53, 2.64]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.DrawerKnobMid.geometry}
          material={materials.Black}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.DrawerTop.geometry}
        material={materials.White}
        position={[11.84, 1.53, 2.64]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.DrawerKnobTop.geometry}
          material={materials.Black}
        />
      </mesh>
      {/* info board */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sampleMainBoard.geometry}
        // material={materials.sampleMainBoard}
        position={[12.22, 5.53, -3.91]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={3.37}
        // ref={cubeRef}
      />
      {/* <Html>
      <div ref={htmlRef} style={['backgroundColor: red']}>
        testing
      </div>
      </Html> */}
    </group>
  );
}

useGLTF.preload("/models/LoungeRoom.gltf");

