import create from 'zustand';
import GUI from 'lil-gui';

export const useStore = create(set => ({
    isProgressFinished: false,
    setProgressFinished: (boolValue) => set({isProgressFinished: boolValue}),
    isVertical: "",
    setVertical: (boolValue) => set({isVertical: boolValue}),
    isLoading: "",
    setLoading: (boolValue) => set({isLoading: boolValue}),
    currentScene: "initial",
    changeScene: (sceneName) =>
        set(() => ({currentScene: sceneName})),
    //for main center
    hovered: "",
    setHovered: (boolValue) => set({hovered: boolValue}),

    debugActive: (window.location.hash === '#debug'),
    gui: new GUI({autoplace: false, width: 400}).show(false),

    // transitionMode: false,
    // transition statuses: running, completed
    setTransition: (transitionMode) =>
        set(() => ({isTransitionOn: transitionMode})),

    // for Camera
    nextScene: "initial",
    startTransition: (scene) => set({nextScene: scene}),

    /* increasePopulation: () => set(state => ({ bears: state.bears + 1 })), */
    /* removeAllBears: () => set({ bears: 0 }) */


    /* LoungeRoom elements*/
    /* editing here doesn't render changes immediately
    *  edit locally and then transfer here*/
    /*Store to be only used for global instances*/
    LRmodel: {
        scenePos: [0,1,-12]
           }
}))

export default useStore;
